import React from 'react'
import { Link } from 'gatsby'
const HomeOppertunity = () => {
  return (
    <div className="py-0 sm:py-36">
      <div className="container mx-auto">
        <div className="w-4/5 mx-auto pb-36 h-max-content">
          <div className="flex flex-wrap items-center">
            <div className="w-full sm:w-1/2 relative">
              <div
                className="w-full bg-cover bg-no-repeat rounded relative h-96 mt-24 lg:mt-0"
                style={{
                  backgroundImage: `url(/images/oppertunity-main.webp)`,
                }}
              >
                <img
                  src="/images/oppertunity-vector-blue.webp"
                  className="absolute top-0 right-0 -mt-12 -mr-10 z-10"
                />
                <img
                  src="/images/oppertunity-vector-red.webp"
                  className="absolute bottom-0 left-0 z-10 home-hero-vector-red -ml-10 -mb-16"
                />
              </div>
            </div>
            <div className="w-full sm:w-1/2 pl-12 mt-8 sm:mt-0">
              <h1 className="font-playfair font-normal text-headingThree uppercase">
                Search thousands of medical sales jobs from top employers
              </h1>
              <h3 className="font-playfair font-normal text-lg mt-8">
                Find better opportunities and better pay on MedReps
              </h3>
              <Link to="/job-search">
                <button className="text-white py-4 px-10 bg-button cursor-pointer font-sans font-bold rounded mt-4 sm:mt-8 text-lg">
                  Search Jobs
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap items-start">
          <div className="w-full sm:w-1/2 flex flex-wrap flex-col items-center bg-oppertunityCardOneBg mt-48 pb-12">
            <div className="w-1/2 -mt-48">
              <div
                className="h-64 shadow-md"
                style={{
                  backgroundImage: `url(/images/shaped-image-one.webp)`,
                }}
              />
              <p className="font-sans text-lg font-bold pt-12 mb-2">
                How much do medical sales reps earn?
              </p>
              <p className="text-sm font-sans font-normal mb-3">
                Get up to date, detailed medical sales salary and commission
                data, plus information on typical additional compensation,
                benefits, rep profiles, and more.
              </p>
              <Link
                to="/salary/"
                className="flex items-center text-xs text-merlot hover:text-merlot"
              >
                Read more
                <svg
                  className="mt-0.5 ml-2"
                  width="15"
                  height="8"
                  viewBox="0 0 15 8"
                  fill="none"
                >
                  <path
                    d="M13.405 3.39022L11.17 0.879598L11.7592 0.217773L15 3.85828L11.7592 7.49878L11.17 6.83695L13.405 4.32633H0V3.39022H13.405Z"
                    fill="#6D1E36"
                  />
                </svg>
              </Link>
            </div>
          </div>
          <div className="w-full sm:w-1/2 mt-56 sm:mt-60">
            <div className="flex flex-col items-center bg-themePink mt-48 pb-12">
              <div className="w-1/2 -mt-64">
                <p className="text-headingThree font-playfair ">
                  Medical Sales Career Advice
                </p>
                <div
                  className="h-64 shadow-md"
                  style={{
                    backgroundImage: `url(/images/shaped-image-two.webp)`,
                  }}
                />
                <p className="font-sans text-lg font-bold pt-12 mb-2">
                  Get Medical Sales Career Advice from Industry Experts
                </p>
                <p className="text-sm font-sans font-normal mb-3">
                  Our career advice focuses on giving you a head start on your
                  medical sales job search, detailed sales advice to help you
                  meet your targets, and providing you with insider knowledge
                  directly from medical sales hiring managers, recruiters, and
                  successful reps!
                </p>
                <Link
                  to="/medical-sales-careers/"
                  className="flex items-center text-xs text-merlot hover:text-merlot"
                >
                  Read more
                  <svg
                    className="mt-0.5 ml-2"
                    width="15"
                    height="8"
                    viewBox="0 0 15 8"
                    fill="none"
                  >
                    <path
                      d="M13.405 3.39022L11.17 0.879598L11.7592 0.217773L15 3.85828L11.7592 7.49878L11.17 6.83695L13.405 4.32633H0V3.39022H13.405Z"
                      fill="#6D1E36"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default HomeOppertunity
