import React from 'react'
import cookie from 'react-cookies'
import Slider from 'react-slick'

const testimonials = [
  {
    image: `url(/images/testimonial-1.png)`,
    heading:
      'Half of MedReps members received a job offer in the last 6 months',
    testimonial:
      'I was able to secure a great job that I love. I have also referred a few friends to sign up. The user interface is easy, and I trust the companies that recruit on the site.',
    nameDate: 'Will S | 2021',
  },
  {
    image: `url(/images/testimonial-2.png)`,
    heading: '85% of members have had an interview',
    testimonial:
      'I have been successful using MedReps in my last three job searches.',
    nameDate: 'Anthony O. | 2021',
  },
  {
    image: `url(/images/testimonial-3.png)`,
    heading: '  90% of members would recommend MedReps to a friend',
    testimonial:
      "I've recommended anyone and everyone looking to break into medical device or pharma sales to sign up on MedReps. It's the perfect platform to begin with for any level of experience.",
    nameDate: 'Brittany G. | 2021',
  },
  {
    image: `url(/images/testimonial-4.png)`,
    heading: 'Join the go-to-site for medical sales',
    testimonial:
      'MedReps takes away the hassle of trying to narrow down and refine job searches on other sites and just gives you the jobs you want to apply to-saving time and money for me. I highly recommend MedReps and will do so going forward!',
    nameDate: 'Frank L. | 2021',
  },
]

const HomeReviewSlider = ({ openSignupModal }) => {
  let user = cookie.load('user')

  const settings = {
    className: 'slick-slider',
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
  }

  return (
    <div className="container mx-auto pb-20 hidden md:block">
      <div className="w-full lg:w-4/5 mx-auto">
        <Slider {...settings}>
          {testimonials.map(testimonial => (
            <div className="flex flex-wrap pl-16 py-16">
              <div className="w-full sm:w-1/2 relative">
                <div
                  className="w-full h-full bg-cover bg-no-repeat rounded relative bg-image"
                  style={{ backgroundImage: testimonial.image }}
                >
                  <img
                    src="/images/home-hero-vector-blue.webp"
                    className="absolute top-0 right-0 -mt-12 -mr-10 z-10"
                  />
                  <img
                    src="/images/home-hero-vector-red.webp"
                    className="absolute z-10 home-hero-vector-red -ml-20 bottom-0 -mb-48"
                  />
                </div>
              </div>
              <div className="w-full sm:w-1/2 pl-12 mt-10 sm:mt-0">
                <h1 className="font-playfair font-normal text-headingThree uppercase">
                  {testimonial?.heading}
                </h1>
                <div className="qoute-div relative">
                  <img src="/images/qoute.png" className="pt-4" />
                  <p className="font-sans text-lg font-normal leading-45">
                    {testimonial.testimonial}
                  </p>
                  <img
                    src="/images/qoute.png"
                    className="rotate-180 absolute right-0 bottom-3"
                  />
                  <p className="font-sans text-lg font-bold pt-4">
                    {testimonial.nameDate}
                  </p>
                  {!user ? (
                    <button
                      className="text-white py-4 px-10 bg-button cursor-pointer font-sans font-bold rounded mt-55 text-lg"
                      onClick={() => {
                        openSignupModal()
                      }}
                    >
                      Join Now
                    </button>
                  ) : (
                    <div className="mt-[140px]" />
                  )}
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}

export default HomeReviewSlider
