import React from 'react'
import Wrapper from '../components/wrapper'
import HomeSearch from '../components/pageComponents/homeSearch'
import EmployerSlider from '../components/pageComponents/employerSlider'
import HomeReviewSlider from '../components/pageComponents/homeReviewSection'
import HomeMembershipCards from '../components/pageComponents/homeMembershipCards'
import HomeOpportunity from '../components/pageComponents/homeOppertunity'
const HomePage = ({ location }) => {
  const Children = props => {
    return (
      <>
        <HomeSearch {...props} />
        <EmployerSlider {...props} />
        <HomeReviewSlider {...props} />
        <HomeMembershipCards {...props} />
        <HomeOpportunity {...props} />
      </>
    )
  }
  return (
    <Wrapper
      location={location}
      title="The Go-To Site for Medical Sales | MedReps"
      description="MedReps is the go-to-site for medical sales reps, including medical device, pharmaceutical and biotech sales jobs."
    >
      <Children />
    </Wrapper>
  )
}
export default HomePage
