import React from 'react'
import { Link } from 'gatsby'
import { useSelector } from 'react-redux'
import { auth0Roles } from '../../../config'
import { permissionsHandler } from '../../../functions'

const HomeMembershipCards = ({ user, openSignupModal, userRole }) => {
  const storeProducts = useSelector(state => state.user).products
  let products = permissionsHandler(storeProducts, user?.role?.id)

  return (
    <div className="py-24 bg-themePink w-full">
      <div className="container mx-auto">
        <div className="w-4/5 mx-auto">
          <h1 className="font-playfair text-headingThree font-normal w-full sm:w-[36%]">
            The go-to site for medical sales reps
          </h1>
          <div className="flex flex-wrap items-cente justify-between pt-4">
            <div className="w-full sm:w-32% bg-white p-8 shadow-md rounded mb-5 sm:mb-0">
              <img src="/icons/goto-icon-1.png" className="mb-4" />
              <p className="text-lg text-black font-sans font-bold mb-3 w-4/5">
                Just medical sales jobs
              </p>
              <p className="text-base font-normal font-sans mb-0">
                Don’t waste time searching through irrelevant jobs – MedReps
                only posts medical sales jobs to make your search faster and
                more efficient
              </p>
            </div>
            <div className="w-full sm:w-32% bg-white p-8 shadow-md rounded mb-5 sm:mb-0">
              <img src="/icons/goto-icon-2.png" className="mb-4" />
              <p className="text-lg text-black font-sans font-bold mb-3 w-4/5">
                Medical sales salary information
              </p>
              <p className="text-base font-normal font-sans mb-0">
                Knowledge is power! Get up to date, detailed medical sales
                salary information
              </p>
            </div>
            <div className="w-full sm:w-32% bg-white p-8 shadow-md rounded">
              <img src="/icons/goto-icon-3.png" className="mb-4" />
              <p className="text-lg text-black font-sans font-bold mb-3 w-4/5">
                Medical sales career advice
              </p>
              <p className="text-base font-normal font-sans mb-0">
                Get specific medical sales career advice to increase your chance
                of getting hired
              </p>
            </div>
          </div>
          {!user ? (
            <button
              onClick={() => openSignupModal()}
              className="text-white py-4 px-10 bg-button cursor-pointer font-sans font-bold rounded w-full sm:w-auto text-lg mt-8"
            >
              Join Now
            </button>
          ) : (
            <>
              {!products?.subscriptionPurchased ? (
                <Link
                  to={
                    userRole === auth0Roles.Member.id
                      ? '/member-subscription/subscription'
                      : '/employer-dashboard'
                  }
                >
                  <button className="text-white py-4 px-10 bg-button cursor-pointer font-sans font-bold rounded text-lg mt-8">
                    Get Membership
                  </button>
                </Link>
              ) : (
                <div />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}
export default HomeMembershipCards
