import React, { useEffect, useMemo, useState } from 'react'
import { navigate, useStaticQuery, graphql } from 'gatsby'
import { Form, Input, Dropdown, Checkbox } from 'antd'
import { Gpt } from 'react-gpt-ads'
import axios from 'axios'
import GooglePlacesAutocomplete from '../../google-place-autocomplete'
import { strapiURL, isBrowser, getAdManagerConfig } from '../../../config'
import { LocationIcon, CloseModalIcon } from '../../icons'
import { restructorArray } from '../../../functions'
import { valuesSetHandler, jobFunctions, SELECT_ALL } from '../../../data'

const HomeSearch = ({ user }) => {
  const websiteTimeQuery = useStaticQuery(graphql`
    query {
      site {
        buildTime
      }
    }
  `)
  const buildTime = new Date(websiteTimeQuery.site.buildTime).toISOString()
  const [jobsCount, setJobsCount] = useState(0)
  const [activeFilter, setActiveFilter] = useState({})
  const [searchForm] = Form.useForm()

  const Menu = ({ items, name, includeSelectAll }) => {
    let selectAllKey = valuesSetHandler(SELECT_ALL)
    if (includeSelectAll && items.indexOf(selectAllKey) == -1) {
      items.splice(0, 0, selectAllKey)
    }

    let _resturcturedData = restructorArray(items, 6)
    let _currentArray = { ...searchForm.getFieldsValue() }
    let currentItem = _currentArray[name]
    let availableValues = []
    _resturcturedData?.map(resItem => {
      resItem?.map(item => {
        availableValues.push(valuesSetHandler(item))
      })
    })

    if (
      includeSelectAll &&
      currentItem &&
      currentItem.length === availableValues.length - 1 &&
      currentItem.indexOf(selectAllKey) == -1
    ) {
      currentItem.splice(0, 0, selectAllKey)
    }

    return (
      <div className="shadow p-6 rounded-md search-filter-dropdown bg-white relative">
        <button
          className="text-merlot absolute right-0 top-0 mr-4 mt-4"
          onClick={e => {
            setActiveFilter({})
          }}
        >
          <CloseModalIcon />
        </button>
        <Checkbox.Group
          value={currentItem}
          className="w-full"
          onChange={values => {
            searchForm.setFieldsValue({
              [name]: values,
            })
          }}
        >
          {_resturcturedData?.map((resItem, resItemIndex) => {
            return (
              <div
                className="flex flex-col mr-12 theme-form-item"
                key={resItemIndex}
              >
                {resItem &&
                  resItem?.map((item, index) => {
                    return (
                      <Checkbox
                        checked={true}
                        className="search-filter-dropdown-item"
                        key={index}
                        value={valuesSetHandler(item)}
                      >
                        {item}
                      </Checkbox>
                    )
                  })}
              </div>
            )
          })}
        </Checkbox.Group>
      </div>
    )
  }
  const DropdownButton = ({ icon, label, items, name }) => {
    let isActive = activeFilter.name === name && activeFilter.visible
    return (
      <Form.Item name={name} className="theme-form-item font-sans">
        <Dropdown
          visible={isActive}
          onVisibleChange={val => {
            setActiveFilter({
              name: name,
              visible: val,
            })
          }}
          trigger={['click']}
          overlay={<Menu data="test" items={items} name={name} />}
        >
          <button
            className={`${
              isActive ? 'border border-merlot' : 'border border-transparent'
            } filter-option flex items-center shadow rounded-md cursor-pointer`}
          >
            <div className="text-side py-3 px-4 border-r border-filterb">
              <p
                className={`${
                  isActive ? ' text-merlot' : 'text-black'
                } font-sans mb-0 text-sm`}
              >
                {label}
              </p>
            </div>
            <div className="icon-side py-3 px-4">
              <img src={`/icons/${icon}.svg`} className="mb-0" name={name} />
            </div>
          </button>
        </Dropdown>
      </Form.Item>
    )
  }

  const formSubmitHandler = values => {
    let filters = {}
    if (values.keyword) {
      filters.keyword = values.keyword
    }
    if (values.primary_function) {
      filters.primary_function = values.primary_function
    }
    if (values.location) {
      filters.place_id = values.location.value?.place_id
      filters.address = values.location.label
    }
    const params = new URLSearchParams(filters)
    navigate(`/job-search/?${params.toString()}`)
  }

  useEffect(() => {
    axios
      .get(strapiURL + `/jobs/count/active?buildTime=${buildTime}`)
      .then(res => {
        setJobsCount(res?.data)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  const config = useMemo(() => {
    const locationPath = isBrowser() ? window.location.pathname : ''
    return getAdManagerConfig(locationPath)
  }, [])

  return (
    <div
      className="pt-0 md:pt-16 pb-16 w-full relative z-10"
      style={{ backgroundImage: `url(/images/home-search-bg.webp)` }}
    >
      <div className="h-[80px] flex items-center justify-center md:hidden bg-white mx-[1px] mb-8">
        <Gpt
          adUnit={config.adUnit}
          name={`${config.id}-mobile`}
          size={config.mobileSize}
        />
      </div>
      <div className="container mx-auto">
        <div className="w-full xl:w-3/5 mx-auto px-6 sm:px-0">
          <h1 className="text-center text-xl sm:text-4xl font-playfair text-white font-headingTwo font-normal mb-12">
            Search {jobsCount?.toLocaleString()} Medical Sales Jobs
          </h1>
          <Form
            onFinish={formSubmitHandler}
            form={searchForm}
            layout="Horizontal"
            className="index-page-search bg-white py-6 px-12 rounded flex flex-wrap justify-between items-center justify-between"
          >
            <div className="w-38 border relative rounded flex basis-full sm:flex-1 mr-0 sm:mr-3 mb-4 sm:mb-0 items-center">
              <Form.Item
                name="keyword"
                className="mb-0 border-none w-full homepage-search"
              >
                {user ? (
                  <Input
                    type="text"
                    className="w-full font-sans text-sm pl-4 py-4 focus:outline-none outline-none text-charcoal border-none"
                    placeholder="Job title or keyword"
                  />
                ) : (
                  <DropdownButton
                    icon="icon-primary-function"
                    label="Primary Function"
                    items={jobFunctions}
                    name="primary_function"
                  />
                )}
              </Form.Item>
            </div>
            <div className="w-38 border relative rounded flex basis-full mr-0 sm:mr-3 sm:flex-1 mb-4 sm:mb-0 items-center">
              <div className="pl-3">
                <LocationIcon />
              </div>
              <Form.Item
                name="location"
                className="google-place-autocomplete w-full mb-0"
              >
                <GooglePlacesAutocomplete
                  placeholder="Location"
                  className="google-place-autocomplete-select-container border-none py-1"
                />
              </Form.Item>
            </div>
            <button
              type="submit"
              className="bg-bgSecondaryButton text-voyage w-full sm:w-auto text-sm font-bold font-sans py-4 px-10 rounded"
            >
              Search
            </button>
          </Form>
        </div>
      </div>
    </div>
  )
}
export default HomeSearch
